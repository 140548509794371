import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import PostCard from "../components/PostCard"
import Cookies from "js-cookie"
import Pagination from "../components/Pagination"
import MetaData from "../components/meta"

// Import styles
import "../styles/blog.scss"

// Images
import ChevronDown from "../images/inline-svgs/chevron-down.svg"
import EmailInputIcon from "../images/inline-svgs/email-input-icon.svg"

interface Props {
  data: any
  location: {
    pathname: string
  }
  pageContext: {
    previousPagePath: string
    nextPagePath: string
    humanPageNumber: number
  }
}

const BlogPage = ({ data, location, pageContext }: Props) => {
  const [isTopicsMenuActive, setTopicsMenu] = useState(false)
  const featured_posts =
    pageContext.humanPageNumber === 1 ? data.blogPosts.edges.slice(0, 3) : []
  const normal_posts =
    pageContext.humanPageNumber === 1
      ? data.blogPosts.edges.slice(3)
      : data.blogPosts.edges

  // Subscription form
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault()

    const isBrowser = typeof window !== "undefined"
    const hutk = isBrowser ? Cookies.get("hubspotutk") : null
    const pageUri = isBrowser ? window.location.href : null
    const pageName = isBrowser ? document.title : null
    const postUrl =
      "https://api.hsforms.com/submissions/v3/integration/submit/5814022/195f7321-e7f5-4fe0-9c3e-49466380956f"

    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "email",
          value: email,
        },
        {
          name: "newsletter",
          value: "Yes",
        },
        {
          name: "lifecyclestage",
          value: "subscriber",
        },
      ],
      context: {
        hutk,
        pageUri,
        pageName,
      },
      legalConsentOptions: {
        consent: {
          // Include this object when GDPR options are enabled
          consentToProcess: true,
          text: "I agree to receive communications from Traefik Labs",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: "I agree to receive communications from Traefik Labs",
            },
          ],
        },
      },
    }

    fetch(postUrl, {
      method: "post",
      body: JSON.stringify(body),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
      }),
    })
      .then(res => res.json())
      .then(() => {
        setMessage("Thanks for submitting the form!")
        setEmail("")
      })
      .catch(err => {
        setMessage("Oops, something has gone wrong")
      })
  }

  return (
    <Layout>
      <MetaData
        location={location}
        title="Blog | Traefik Labs"
        description="Visit our blog to get the latest news on our products, the community, and also some interesting articles related to containers, microservices, Kubernetes and more."
      />
      <div className="blog">
        {/* Topics Navigation */}
        <div className="blog__topics">
          <div className="wrapper-1200">
            {/* Desktop */}
            <div className="topics">
              <div className="title">Topics:</div>
              <div className="items">
                <Link to="/tag/announcements/">Announcements</Link>
                <Link to="/tag/case-studies/">Case Studies</Link>
                <Link to="/tag/community/">Community</Link>
                <Link to="/tag/company/">Company</Link>
                <Link to="/tag/docker/">Docker</Link>
                <Link to="/tag/enterprise/">Enterprise</Link>
                <Link to="/tag/how-to/">How To</Link>
                <Link to="/tag/kubernetes/">Kubernetes</Link>
                <Link to="/tag/microservices/">Microservices</Link>
                <Link to="/tag/partners/">Partners</Link>
                <Link to="/tag/product-news/">Product News</Link>
              </div>
            </div>

            {/* Mobile */}
            <div
              className={
                "topics__dropdown " + (isTopicsMenuActive ? "open" : "")
              }
            >
              <button
                className="current-topic"
                onClick={() => setTopicsMenu(!isTopicsMenuActive)}
              >
                <div className="title">All Topics</div>
                <div className="icon">
                  <ChevronDown />
                </div>
              </button>
              <div className="other-topics">
                <Link to="/tag/announcements/">Announcements</Link>
                <Link to="/tag/case-studies/">Case Studies</Link>
                <Link to="/tag/community/">Community</Link>
                <Link to="/tag/company/">Company</Link>
                <Link to="/tag/docker/">Docker</Link>
                <Link to="/tag/enterprise/">Enterprise</Link>
                <Link to="/tag/how-to/">How To</Link>
                <Link to="/tag/kubernetes/">Kubernetes</Link>
                <Link to="/tag/microservices/">Microservices</Link>
                <Link to="/tag/partners/">Partners</Link>
                <Link to="/tag/product-news/">Product News</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Featured Posts */}
      {pageContext.humanPageNumber === 1 && (
        <div className="blog__featured">
          <div className="wrapper-1200">
            <div className="post-feed">
              {featured_posts.map(({ node }) => (
                <PostCard key={node.id} post={node} />
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Subscribe */}
      {pageContext.humanPageNumber === 1 && (
        <div className="blog__subscribe">
          <div id="subscribe"></div>
          <div className="wrapper-1200">
            <div className="blog__subscribe-header">
              Get the latest news and announcements monthly
            </div>
            <form className="blog__subscribe-form" onSubmit={submitForm}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="email-field">
                  <input
                    type="email"
                    name="email"
                    placeholder="Your email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                  <div className="email-symbol">
                    <EmailInputIcon />
                  </div>
                </div>
                <button
                  className="button--primary button--primary--small"
                  type="submit"
                >
                  Subscribe
                </button>
              </div>
              <div className="legal-notice">
                <input
                  type="checkbox"
                  id="blogSubscribe"
                  name="subscribe"
                  value="subscribe"
                  required
                />
                <label htmlFor="blogSubscribe">
                  I agree to receive communications from Traefik Labs
                </label>
              </div>
            </form>
            <p
              style={{
                fontSize: "17px",
                margin: "16px 0 0",
                opacity: "0.7",
                display: message.length === 0 ? "none" : "block",
              }}
            >
              {message}
            </p>
          </div>
        </div>
      )}

      {/* Normal Posts */}
      <div className="blog__posts">
        <div className="wrapper-1200">
          <div className="post-feed">
            {normal_posts.map(({ node }) => (
              <PostCard key={node.id} post={node} />
            ))}
          </div>
        </div>
      </div>

      {/* Pagination */}
      <div className="blog__pagination wrapper-1200">
        <Pagination pageContext={pageContext} />
      </div>
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query blogPageQueries($limit: Int!, $skip: Int!) {
    blogPosts: allGhostPost(
      filter: { primary_tag: { slug: { eq: "blog" } } }
      sort: { order: DESC, fields: [published_at] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...GhostPostCardFields
          primary_author {
            name
            slug
            profile_image
            profileImageSharp {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 32, height: 32)
              }
              publicURL
            }
          }
        }
      }
    }
  }
`
